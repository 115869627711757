import { Typography, Button, Container, Card, CardContent, CardActions, FormControlLabel, Switch as MuiSwitch, Paper } from '@mui/material';
import { styled } from '@mui/system';
import onehas_Leaf from "../images/onehas_Leaf.png";
import Lighting from "../images/onehas_Lightning.png";
import Damiond from "../images/onehas_Diamond.png";
import checkIcon from "../images/onehastick.png";
import whitecheckIcon from "../images/white_tick2.png"
import saveonehas from '../images/onehassave.svg';
import { useState } from 'react';
import Box from "@mui/material/Box"
import { useNavigate } from 'react-router-dom';



// const CustomSwitch = styled(MuiSwitch)(({ theme }) => ({
//     '& .MuiSwitch-switchBase': {
//         borderRadius: '50px',
//     },
//     '& .MuiSwitch-thumb': {
//         borderRadius: '50%',
//     },
//     '& .MuiSwitch-track': {
//         borderRadius: '50px',
//     },
// }));



// const BillingOptions = styled('div')({
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     position: 'relative',
//     marginTop: '0px auto'
// });

const SaveImage = styled('img')({
    position: 'absolute',
    left: 'calc(50% - 90px)',
    top: '290px',
    transform: 'translateX(-100%)',
    width: '90px',
    height: 'auto',
});

// const BillingSwitchWrapper = styled('div')({
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// });


const Plan = styled(Card, { shouldForwardProp: (prop) => prop !== 'isSelected' })(
    ({ isSelected, isTeamPlan }) => ({
        width: '230px',
        height: '420px',
        padding: '20px',
        textAlign: 'center',
        borderRadius: '15px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        border: isSelected ? '2px solid #007ee5' : 'none',
        transform: isSelected ? 'scale(1.05)' : 'none',
        backgroundColor: isTeamPlan ? '#007ee5' : isSelected ? '#f0f9ff' : '#fff',
        color: isTeamPlan ? '#fff' : isSelected ? '#007ee5' : '#000',
        position: 'absolute',
        '& .MuiTypography-root': {
            color: isTeamPlan ? '#fff' : isSelected ? '#A3AFB9' : '#000',
        },
        '& .MuiChip-root': {
            color: isTeamPlan ? '#007ee5' : isSelected ? '#007ee5' : '#000',
            backgroundColor: isTeamPlan ? '#fff' : isSelected ? '#f0f9ff' : '#fff',
        },
        '& .FeatureItem': {
            color: isTeamPlan ? '#fff' : isSelected ? '#007ee5' : '#000',
        },
    })
);

const CenteredCardActions = styled(CardActions)({
    justifyContent: 'center',
});

const FeatureList = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '10px',
});

const FeatureItem = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    color: '#656b7d',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '500',
    lineHeight: '1.2',
    gap: '10px',
});


const FusionerpScription = () => {
    const [billingOption, setBillingOption] = useState('monthly');
    const [price, setPrice] = useState(49);

    const handleBillingChange = (event) => {
        const newBillingOption = event.target.checked ? 'monthly' : 'yearly';
        setBillingOption(newBillingOption);
        setPrice(newBillingOption === 'monthly' ? 49 : 39);
    };
    
    const navigate = useNavigate();
    const handleClick = (plan,price) => {
        navigate(`/signup?defaultValue=CRM&plan=${encodeURIComponent(plan)}&price=${price}`);
    };
    return (

        <>
            <Container>

            <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 1,
                        flexDirection: {
                            xs: 'column', 
                            sm: 'row',   
                        },
                        px: { xs: 2, sm: 3 },
                    }}
                >
                    <SaveImage
                        src={saveonehas}
                        alt="Save Image"
                        sx={{
                            mb: { xs: 2, sm: 0 }, 
                            mt: { xs: 11, sm: 0,md:2 },
                            width: {
                                xs: '25%', 
                                sm: 'auto',
                            },
                            maxWidth: '200px',
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexGrow: 1,
                            mt: { xs: 1, sm: 0 },
                            ml: { xs: 0, sm: 'auto' }, 
                            mr: { xs: 2, sm: 0 },
                        }}
                    >
                        <FormControlLabel
                            control={
                                <MuiSwitch
                                    checked={billingOption === 'monthly'}
                                    onChange={handleBillingChange}
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: 'white',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: '#007ee5',
                                        },
                                    }}
                                />
                            }
                            label={billingOption === 'monthly' ? 'Billed Monthly' : 'Billed Yearly'}
                            sx={{
                                typography: {
                                    xs: 'caption',
                                    sm: 'body1',
                                },
                            }}
                        />
                    </Box>
                </Box>


                <Paper elevation={0} sx={{ p: 2, display: { xs: 'none', md: 'flex' },justifyContent:'center' }} maxWidth='lg'>
                    <Plan                     sx={{
                        width: { xs: '90%', sm: '45%', lg: '25%' }, 
                        marginBottom: 4, 
                        position: 'relative',
                        top: { xs: '0px', sm: '0px', lg: '60px' }, 
                    }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box>
                                    <img
                                        src={onehas_Leaf}
                                        alt="Leaf icon for Individual Plan"
                                        style={{ width: '70px', height: '70px', marginLeft: '-20px' }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: "#01178e !important",
                                            fontFamily: 'Poppins, sans-serif',
                                            fontSize: '16px',
                                            fontWeight: 1000,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            textAlign: 'left',
                                            marginBottom: '20px',
                                            marginTop: '0px',
                                            marginLeft: '12px'
                                        }}
                                    >
                                        Individual <br />
                                        Plan
                                    </Typography>
                                    <Typography sx={{ fontWeight: 1000, fontSize: '18px', textAlign: 'left', marginTop: '-20px', marginLeft: '10px', textTransform: 'none' }}>
                                        Free
                                    </Typography>
                                </Box>
                            </Box>
                            <hr style={{ border: 'none', borderTop: '1px solid #ddd', margin: '20px 0', width: '100%' }} />
                            <FeatureList sx={{ fontSize: '15px', fontWeight: 500 }}>
                                <FeatureItem><img src={checkIcon} alt="tick" />Single User</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Hosted only</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Unlimited event</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Google Calendar</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Microsoft</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Workflows</FeatureItem>
                            </FeatureList>
                        </CardContent>
                        <CenteredCardActions>
                            <Button
                                variant="contained" color="primary"
                                sx={{
                                    borderRadius: '20px',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    height: '60px',
                                    minWidth: '220px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '0px 20px',
                                    textTransform: 'none',
                                    transform: 'scale(1)',
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(0.95)',
                                    }
                                }}
                                onClick={() => handleClick('IndividualPlan', 0)} 
                            >
                                Get Started
                            </Button>
                        </CenteredCardActions>
                    </Plan>
                    <Plan
                        sx={{
                        width: { xs: '90%', sm: '45%', lg: '31%' },
                        height:{xs:0,sm:0,md:'25%'},
                        marginBottom: 4,
                        backgroundColor: '#007ee5',
                        color: 'white',
                        border: '3px solid white',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                        position: 'relative',
                        zIndex: 2, 
                        top: { xs: '10px', sm: '20px', lg: '20px' }, 
                        }}
                    >
                        <CardContent>
                            {/* Limited Offer Label */}
                            <Paper elevation={8} sx={{
                                position: 'absolute',
                                top: '-3px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                backgroundColor: 'white',
                                color: 'blue',
                                padding: '5px 8px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                zIndex: 1


                            }}>
                                Limited Time Offer
                            </Paper>

                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box>
                                    <img
                                        src={Lighting}
                                        alt="Advanced Plan"
                                        style={{ width: '70px', height: '70px', marginLeft: '-20px' }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: 'white !important',
                                            fontFamily: 'Poppins, sans-serif',
                                            fontSize: '20px',
                                            fontWeight: 700,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            textAlign: 'left',
                                            marginBottom: '20px',
                                            marginTop: '0px',
                                            marginLeft: '12px',
                                        }}
                                    >
                                        Team Plan<br />
                                        Free
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'left', marginTop: '-20px', marginLeft: '10px', color: 'white !important', fontFamily: 'Poppins, sans-serif !important' }}>
                                        ${price}/month
                                    </Typography>
                                </Box>
                            </Box>
                            <hr style={{ border: 'none', borderTop: '1px solid #ddd', margin: '20px 0', width: '100%' }} />
                            <FeatureList sx={{ fontSize: '12px', fontWeight: 500 }}>
                                <FeatureItem sx={{ color: 'white !important', fontSize: '15px', fontWeight: 500 }}><img src={whitecheckIcon} alt="tick" />CRM</FeatureItem>
                                <FeatureItem sx={{ color: 'white !important', fontSize: '15px', fontWeight: 500 }}><img src={whitecheckIcon} alt="tick" />Unlimited team</FeatureItem>
                                <FeatureItem sx={{ color: 'white !important', fontSize: '15px', fontWeight: 500 }}><img src={whitecheckIcon} alt="tick" />Hosted only</FeatureItem>
                                <FeatureItem sx={{ color: 'white !important', fontSize: '15px', fontWeight: 500 }}><img src={whitecheckIcon} alt="tick" />Unlimited event</FeatureItem>
                                <FeatureItem sx={{ color: 'white !important', fontSize: '15px', fontWeight: 500 }}><img src={whitecheckIcon} alt="tick" />Team pages</FeatureItem>
                                <FeatureItem sx={{ color: 'white !important', fontSize: '15px', fontWeight: 500 }}><img src={whitecheckIcon} alt="tick" />Email and Chat support</FeatureItem>
                                <FeatureItem sx={{ color: 'white !important', fontSize: '15px', fontWeight: 500 }}><img src={whitecheckIcon} alt="tick" />and many more...</FeatureItem>
                            </FeatureList>
                        </CardContent>
                        <CenteredCardActions>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    borderRadius: '20px',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    height: '60px',
                                    minWidth: '300px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '0px 20px',
                                    textTransform: 'none',
                                    backgroundColor: 'white !important',
                                    color: '#005bb5',
                                    transform: 'scale(1)',
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(0.95)',
                                    }
                                }}
                                onClick={() => handleClick('TeamPlan', price)}
                            >
                                Get Started
                            </Button>
                        </CenteredCardActions>
                    </Plan>
                    <Plan
                        sx={{
                        width: { xs: '90%', sm: '45%', lg: '24%' },
                        marginBottom: 4,
                        position: 'relative',
                        zIndex: 0,
                        top: { xs: '0px', sm: '0px', lg: '50px' },
                        }}
                    >
                        <CardContent>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box>
                                    <img
                                        src={Damiond}
                                        alt="Individual Plan"
                                        style={{ width: '70px', height: '70px', marginLeft: '-20px' }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: "#01178e !important",
                                            fontFamily: 'Poppins, sans-serif',
                                            fontSize: '16px',
                                            fontWeight: 1000,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            textAlign: 'left',
                                            marginBottom: '20px',
                                            marginTop: '0px',
                                            marginLeft: '12px'
                                        }}
                                    >
                                        Enterprise<br />
                                        Plan
                                    </Typography>
                                    <Typography sx={{ fontWeight: 1000, fontSize: '18px', textAlign: 'left', marginTop: '-20px', marginLeft: '10px' }}>
                                        Contact <br />
                                        Us
                                    </Typography>
                                </Box>
                            </Box>
                            <hr style={{ border: 'none', borderTop: '1px solid #ddd', margin: '10px 0', width: '100%' }} />
                            <FeatureList sx={{ fontSize: '15px', fontWeight: 500 }}>
                                <FeatureItem><img src={checkIcon} alt="tick" />Single User</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Hosted only</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Unlimited event</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Google Calendar</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Microsoft</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Workflows</FeatureItem>
                            </FeatureList>
                        </CardContent>
                        <CenteredCardActions>
                            <Button
                                variant="contained" color="primary"
                                sx={{
                                    borderRadius: '20px',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    height: '60px',
                                    minWidth: '200px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textTransform: 'none',
                                    marginTop: '-10px',
                                    transform: 'scale(1)',
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(0.95)',
                                    }

                                }}
                                onClick={() => navigate('/contact')}
                            >
                                Scheldule call
                            </Button>
                        </CenteredCardActions>
                    </Plan>
                </Paper>
                <Paper elevation={0} size='sm' sx={{ p: 2, display: { xs: 'flex', md: 'none' }, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', maxWidth: '100% !important', margin: 'auto' }}  >
                    <Box sx={{ width: '400px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', justifyContent: 'space-evenly', borderRadius: '10px' }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100% !important' }}>
                                <Box>
                                    <img
                                        src={onehas_Leaf}
                                        alt="Individual Plan"
                                        style={{ width: '100%', height: '100%' }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: "#01178e !important",
                                            fontFamily: 'Poppins, sans-serif',
                                            fontSize: '16px',
                                            fontWeight: 1000,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            textAlign: 'left',
                                            marginBottom: '20px',
                                            marginTop: '0px',
                                            marginLeft: '12px'
                                        }}
                                    >
                                        Individual Plan
                                    </Typography>
                                    <Typography sx={{ fontWeight: 1000, fontSize: '18px', textAlign: 'left', marginTop: '-20px', marginLeft: '10px', textTransform: 'none' }}>
                                        Free
                                    </Typography>
                                </Box>
                            </Box>
                            <hr style={{ border: 'none', borderTop: '1px solid #ddd', margin: '20px 0', width: '100%' }} />
                            <FeatureList sx={{ fontSize: '15px', fontWeight: 500 }}>
                                <FeatureItem><img src={checkIcon} alt="tick" />Single User</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Hosted only</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Unlimited event</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Google Calendar</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Microsoft</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Workflows</FeatureItem>
                            </FeatureList>
                        </CardContent>
                        <CenteredCardActions>
                            <Button
                                variant="contained" color="primary"
                                sx={{
                                    borderRadius: '30px',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    height: '50px',
                                    minWidth: '100% !important',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '0px 20px',
                                    textTransform: 'none',
                                    transform: 'scale(1)',
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(0.95)',
                                    }
                                }}
                                onClick={() => handleClick('IndividualPlan', 0)} 
                            >
                                Get Started
                            </Button>
                        </CenteredCardActions>
                    </Box>
                    <Box style={{
                        position: 'relative', height: '100%', width: '400px',
                        backgroundColor: '#007ee5', color: 'white', border: '10px solid white',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                        justifyContent: 'space-evenly', borderRadius: '10px',
                        marginTop: '30px',
                    }}>
                        <CardContent>
                            {/* Limited Offer Label */}
                            <Paper elevation={8} sx={{
                                position: 'absolute',
                                top: '-20px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                backgroundColor: 'white',
                                color: 'blue',
                                padding: '5px 20px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                zIndex: 1


                            }}>
                                Limited Offer
                            </Paper>

                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box>
                                    <img
                                        src={Lighting}
                                        alt="Advanced Plan"
                                        style={{ width: '70px', height: '70px', }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: 'white !important',
                                            fontFamily: 'Poppins, sans-serif',
                                            fontSize: '20px',
                                            fontWeight: 700,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            textAlign: 'left',
                                            marginBottom: '20px',
                                            marginTop: '0px',
                                            marginLeft: '12px',
                                        }}
                                    >
                                        Team Plan
                                        Free
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'left', marginTop: '-20px', marginLeft: '10px', color: 'white !important', fontFamily: 'Poppins, sans-serif !important' }}>
                                        ${price}/month
                                    </Typography>
                                </Box>
                            </Box>
                            <hr style={{ border: 'none', borderTop: '1px solid #ddd', margin: '20px 0', width: '100%' }} />
                            <FeatureList sx={{ fontSize: '12px', fontWeight: 500 }}>
                                <FeatureItem sx={{ color: 'white !important' }}><img src={checkIcon} alt="tick" />CRM</FeatureItem>
                                <FeatureItem sx={{ color: 'white !important' }}><img src={checkIcon} alt="tick" />Unlimited team</FeatureItem>
                                <FeatureItem sx={{ color: 'white !important' }}><img src={checkIcon} alt="tick" />Hosted only</FeatureItem>
                                <FeatureItem sx={{ color: 'white !important' }}><img src={checkIcon} alt="tick" />Unlimited event</FeatureItem>
                                <FeatureItem sx={{ color: 'white !important' }}><img src={checkIcon} alt="tick" />Team pages</FeatureItem>
                                <FeatureItem sx={{ color: 'white !important' }}><img src={checkIcon} alt="tick" />Email and Chat support</FeatureItem>
                                <FeatureItem sx={{ color: 'white !important' }}><img src={checkIcon} alt="tick" />and many more...</FeatureItem>
                            </FeatureList>
                        </CardContent>
                        <CenteredCardActions>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    borderRadius: '30px',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    height: '50px',
                                    minWidth: '200px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '0px 20px',
                                    textTransform: 'none',
                                    backgroundColor: 'white !important',
                                    color: '#005bb5',
                                    transform: 'scale(1)',
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(0.95)',
                                    }
                                }}
                                onClick={() => handleClick('TeamPlan', price)}
                            >
                                Get Started
                            </Button>
                        </CenteredCardActions>
                    </Box>
                    <Box style={{ width: '400px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', justifyContent: 'space-evenly', marginTop: '30px' }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box>
                                    <img
                                        src={Damiond}
                                        alt="Individual Plan"
                                        style={{ width: '70px', height: '70px', }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: "#01178e !important",
                                            fontFamily: 'Poppins, sans-serif',
                                            fontSize: '16px',
                                            fontWeight: 1000,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            textAlign: 'left',
                                            marginBottom: '20px',
                                            marginTop: '0px',
                                            marginLeft: '12px'
                                        }}
                                    >
                                        Enterprise
                                        Plan
                                    </Typography>
                                    <Typography sx={{ fontWeight: 1000, fontSize: '18px', textAlign: 'left', marginTop: '-20px', marginLeft: '10px' }}>
                                        Contact
                                        Us
                                    </Typography>
                                </Box>
                            </Box>
                            <hr style={{ border: 'none', borderTop: '1px solid #ddd', margin: '10px 0', width: '100%' }} />
                            <FeatureList sx={{ fontSize: '15px', fontWeight: 500 }}>
                                <FeatureItem><img src={checkIcon} alt="tick" />Single User</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Hosted only</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Unlimited event</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Google Calendar</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Microsoft</FeatureItem>
                                <FeatureItem><img src={checkIcon} alt="tick" />Workflows</FeatureItem>
                            </FeatureList>
                        </CardContent>
                        <CenteredCardActions>
                            <Button
                                variant="contained" color="primary"
                                sx={{
                                    borderRadius: '30px',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    height: '50px',
                                    minWidth: '200px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '0px 20px',
                                    textTransform: 'none',
                                }}
                            >
                                Scheldule call
                            </Button>
                        </CenteredCardActions>
                    </Box>
                </Paper>
            </Container >
        </>
    )
}


export default FusionerpScription;
