import { Container, Typography, Link, Box, Button ,Grid} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';


const socialLinks = [
  {  icon: <FacebookIcon />, alt: 'Facebook' },
  {  icon: <TwitterIcon />, alt: 'Twitter' },
  {  icon: <LinkedInIcon />, alt: 'LinkedIn' },
  {  icon: <YouTubeIcon />, alt: 'YouTube' },
];

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(4, 0),
  },
  logo: {
    width: 261,
    height: 51,
  },
  footerText: {
    paddingTop: theme.spacing(3),
    color: '#0A0A33',
  },
  footerLinks: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    gap: theme.spacing(1),
  },
  footerLink: {
    marginBottom: theme.spacing(1),
    color: 'black',
    textDecoration: 'none',
    fontSize: '12px',
    fontFamily: '"DM Sans", sans-serif !important',
  },
  footerSectionTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    fontFamily: '"DM Sans", sans-serif !important',
  },
  socialIcons: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      marginRight: theme.spacing(1),
    },
  },
  certificationIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(5),
  },
  appIcons: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      marginRight: theme.spacing(1),
    },
  },
  font: {
    fontFamily: '"DM Sans", sans-serif !important',
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth='100%'>
        <Box display="flex" justifyContent='center' flexWrap="wrap" mb={4}>
          <Box display="flex" gap={2}>
            {socialLinks.map((link, index) => (
              <Link key={index} href={link.href} target="_blank" sx={{ color: 'black' }}>
                {link.icon}
              </Link>
            ))}
          </Box>
        </Box>


        <Box mb={4}>
      <Grid container spacing={2} justifyContent="space-between">
        {/* Trust in Power of FusionERP */}
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Typography variant="h5" className="heading-61" sx={{ fontSize: '14px', marginTop: '10px', whiteSpace: 'nowrap',}}>Trust in power of FusionERP</Typography>
        </Grid>
        {/* Products Section */}
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Typography variant="h5" className="heading-61" sx={{ fontSize: '14px', marginTop: '10px', fontWeight: 'bold' }}>Products</Typography>
          <Link href="/hrms" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>FusionERP HRMS</Typography>
          </Link>
          <Link href="/crm" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>FusionERP CRM</Typography>
          </Link>
          <Link href="/erp" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>FusionERP ERP</Typography>
          </Link>
          <Link href="/healthcare" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>FusionERP HealthCare</Typography>
          </Link>
          <Link href="/assets" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>FusionERP Asset Management</Typography>
          </Link>

        </Grid>

        {/* Pricing Section */}
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Typography variant="h5" className="heading-61" sx={{ fontSize: '14px', marginTop: '10px', fontWeight: 'bold' }}>Pricing</Typography>
          <Link href="/pricing" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Plans</Typography>
          </Link>
        </Grid>

        {/* Resource Section */}
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Typography variant="h5" className="heading-61" sx={{ fontSize: '14px', marginTop: '10px', fontWeight: 'bold' }}>Resource</Typography>
          <Link href="/aboutus" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>About Us</Typography>
          </Link>
          <Link href="" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Blog</Typography>
          </Link>
          <Link href="" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Career</Typography>
          </Link>
          <Link href="https://www.youtube.com/channel/UCY0f15afDUUYR8feVspWFIg/videos" target="_blank" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Tutorials</Typography>
          </Link>
          <Link href="" target="_blank" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Brand Kit</Typography>
          </Link>
          <Link href="" target="_blank" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Roadmap</Typography>
          </Link>
          <Link href="" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Watch/Book Demo</Typography>
          </Link>
          <Link href="" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>FAQ</Typography>
          </Link>
        </Grid>

        {/* Get Help Section */}


        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Typography variant="h5" className="heading-61" sx={{ fontSize: '14px', marginTop: '10px', fontWeight: 'bold' }}>Get Help</Typography>
          <Link href="/about" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Chat Support</Typography>
          </Link>
          <Link href="" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Email Sales</Typography>
          </Link>
          <Link href="" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Email Support</Typography>
          </Link>
          <Link href="https://www.youtube.com/channel/UCY0f15afDUUYR8feVspWFIg/videos" target="_blank" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Help Docs</Typography>
          </Link>
          <Link href="" target="_blank" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Contact</Typography>
          </Link>
          <Link href="" target="_blank" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Escalation Process</Typography>
          </Link>
          <Link href="" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Customer Service Support</Typography>
          </Link>

        </Grid>


        {/* <Grid item xs={12} sm={6} md={4} lg={2}>
          <Typography variant="h5" className="heading-61" sx={{ fontSize: '14px', marginTop: '10px', fontWeight: 'bold' }}>Get Help</Typography>
          <Box className="html-embed-3 w-embed">
            <Button className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Chat Support</Button>
          </Box>
          <Link href="" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Email Sales</Typography>
          </Link>
          <Link href="" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Email Support</Typography>
          </Link>
          <Link href="" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Help Docs</Typography>
          </Link>
          <Link href="/contact" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Contact</Typography>
          </Link>
          <Link href="" className="link-block-22 w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Escalation Process</Typography>
          </Link>
          <Link href="" className="link-block-css w-inline-block" sx={{ textDecoration: 'none !important' }}>
            <Typography className="text-block-58" sx={{ margin: '5px auto !important', fontSize: '14px', color: '#535b62' }}>Customer Service Support</Typography>
          </Link>
        </Grid> */}
      </Grid>
    </Box>







        <Box className="lisence-div" sx={{ textAlign: 'left', mt: 4, fontSize: '12px !important' }}>
          <Typography className="text-block-1251" sx={{ fontSize: '12px' }}>
            <strong>License Notice & Copyright Disclaimer</strong>
            <br />
            FusionERP CRM/ERP (by FusionERP) is fork of & built over the top of ERP. Copyright©Frappe Technologies Pvt Ltd ('Frappe') and Contributors. ERP is a free software: you can redistribute it and/or modify it under the terms of the GNU General Public License as published by the Free Software Foundation, either version 3 of the License, or (at your option) any later version. The support documentation is licensed from <Link href="https://docs.erpnext.com/" className="link-72">ERP Docs</Link> by <Link href="https://docs.erpnext.com/" className="link-72">Frappe</Link> under <Link href="https://creativecommons.org/licenses/by-sa/3.0/deed.en" className="link-72">CC BY-SA 3.0</Link>
          </Typography>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;