import React from "react";
import { Typography, Container, Button, Box, Grid, Icon } from "@mui/material";
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Footer from "./Footerhcm";
import cc3 from '../images/crm-content-3.svg';
import './HRMS.css';
import { useInView } from 'react-intersection-observer';
import Healtcareimage from '../images/Fusionerphealtcare.png'
import health1 from "../images/healthcareimage.png"
import c1 from "../images/Content1-1.png"
import c2 from "../images/content2-2.png";
import c3 from "../images/Content1-3.png"
import content2image from "../images/content2.png"
import c31 from "../images/conten3-1.png"
import c32 from "../images/content3-2.png"
import c33 from "../images/content3-3.png"
import content3 from "../images/content3image.avif"


import h1 from "../images/h1.svg"
import h2 from "../images/h2.svg"
import h3 from "../images/h3.svg"
import h4 from "../images/h4.svg"
import h5 from "../images/h5.svg"
import h6 from "../images/h6.svg"
import h7 from "../images/h7.svg"
import h8 from "../images/h8.svg"
import h9 from "../images/h9.svg"
import h10 from "../images/h10.svg"
import h11 from "../images/h11.svg"
import h12 from "../images/h12.svg"


const healthcareitems = [
    {
      image: h1,
      text: 'Healthcare software product companies',
    },
    {
      image:h2,
      text: 'Healthcare providers',
    },
    {
      image: h3,
      text: 'Healthcare organizations',
    },
    {
      image: h4,
      text: 'Medical device manufacturers',
    },
    {
      image: h5,
      text: 'Pharmaceutical companies',
    },
    {
      image:h6,
      text: 'Biotech and life science companies',
    },
    {
      image: h7,
      text: 'Healthcare NGOs',
    },
    {
      image:h8,
      text: 'Medical Education and Research Center',
    },
    {
      image: h9,
      text: 'Laboratory and Diagnostic Center',
    },
    {
      image: h10,
      text: 'Rehabilitation and Physiotherapy Centers',
    },
    {
      image:h11,
      text: 'Medical Equipment Suppliers',
    },
    {
      image: h12,
      text: 'Healthcare Regulatory Authorities',
    },
  ];

  
const content = [
    {
        title: "Efficiency and Streamlined Operations",
        description: "Implementing an FusionERP Healthcare system in healthcare boosts efficiency by consolidating patient records, staff schedules, and admin tasks onto one platform.",
        image: "https://sanskartechnolab.com/assets/astro_sanskar/astro_sanskar/assets/_astro/Efficiency_Streamlined_Operations.71518a36_J9mtK.svg",
    },
    {
        title: "Enhanced Patient Care",
        description: "FusionERP Healthcare systems centralize patient information, enabling prompt, informed decisions, personalized care, accurate diagnoses, and improved staff collaboration.",
        image: "https://sanskartechnolab.com/assets/astro_sanskar/astro_sanskar/assets/_astro/Enhanced_Patient_Care.4049a27f_Z1n9DIp.svg",
    },
    {
        title: "Cost Reduction",
        description: "FusionERP Healthcare systems reduce operational costs, minimize paperwork, and improve inventory management, and administrative work leading to significant cost savings.",
        image: "https://sanskartechnolab.com/assets/astro_sanskar/astro_sanskar/assets/_astro/Cost_Reduction.2a24181b_Z1432n4.svg",
    },
    

]
const content1 = [
    {
        title: "Improved Reporting and Data Management",
        description: "FusionERP software helps healthcare professionals efficiently manage data and generate reports by centralizing patient records, schedules, and inventory.",
        image:c1
    },
    {
        title: "Better Supply Chain Management",
        description: "FusionERP optimizes supply chain management by monitoring inventory and automating procurement, ensuring timely medical supplies and preventing shortages or overstocking.",
        image: c2
    },
    {
        title: "Efficient Hospital Visit",
        description: "Reduces the waiting time for patients, ensuring they receive essential care more promptly and efficiently at the hospital",
        image: c3
    },
    

]
const content2 = [
    {
        title: "Increased Productivity",
        description: "By easily automating inter-department procedures, Frappe Health allows greater productiveness in healthcare institutions.",
        image: c31
    },
    {
        title: "Improved Communication",
        description: "Users can coordinate and talk amongst many departments in actual time due to the fact of the intertwined workflows.",
        image: c32
    },
    {
        title: "Easy to customise",
        description: "Since Frappe Health is an open-source program, it is easy to regulate it to meet the special wishes of healthcare facilities.",
        image: c33
    },
   

]



const MainTitle = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '3rem',
    marginBottom: '1rem',
    fontFamily: '"Poppins", sans-serif !important',
    color: '#474d66'
});

const SubTitle = styled(Typography)({
    fontSize: '1.25rem',
    marginBottom: '2rem',
    fontFamily: '"Poppins", sans-serif !important',
    color: '#8f95b2',


});

const CallToAction = styled(Button)({
    backgroundColor: '#3f51b5',
    color: '#fff',
    textTransform: 'none',
    padding: '0.75rem 1.5rem',
    fontSize: '1rem',
    borderRadius: '100px',
    fontFamily: '"Poppins", sans-serif !important',
    '&:hover': {
        backgroundColor: '#fff',
        color: '#585bd5',
        border: '2px solid #585bd5',
        boxShadow: 'none',
        fill: '#585bd5',
    },
});

const HeroContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gridRowGap: '30px',
    width: '100%', 
    maxWidth: '1000px',
    margin: '0 auto',
    maxHeight:'300px'
  }));

const HeroLogo = styled(Box)({
    display: 'inline-block',
    backgroundColor: '#fff',
    borderRadius: '50%',
    padding: '25px',
    marginBottom: '20px',
    marginTop: '20px',

});
const HeroButton = styled(Button)({
    height: '60px',
    width: '160px',
    borderRadius: '100px !important',
    /* padding: 8px 13px; */
    transition: '0.2s',
    textTransform: 'none!important',
    boxShadow: 'none'
});

const ExploreButton = styled(Button)({
    lineHeight: 1.5, fontWeight: 500, backgroundColor: '#fff', border: '1px solid #d6d6d8', textTransform: 'none !important', padding: '1rem 1rem', width: '220px', borderRadius: '4rem', marginTop: '20px !important', fontFamily: '"Poppins", sans-serif !important', fontSize: '16px',
    '&:hover': {
        background: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    }
})

const MainTypo = styled(Typography)({
    color: '#fff',
    fontWeight: 500,
    lineHeight: 1.3,
    fontFamily: 'poppins, sans-serif !important '
})

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
});
const useStyles = makeStyles((theme) => ({
    image: {
        [theme.breakpoints.up('md')]: {
            width: '550px !important', height: '450px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
    },
    image1: {
        [theme.breakpoints.up('md')]: {
            width: '670px !important', height: '450px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
    },
    image2: {
        [theme.breakpoints.up('md')]: {
            width: '685px !important', height: '390px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
    },
    image3: {
        [theme.breakpoints.up('md')]: {
            width: '550px !important', height: '450px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
    },
    imageContainer: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '12px',
            maxWidth: '100% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '4px !important',
            textAlign: 'left !important',
            marginTop: '10px'
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '12px',
            maxWidth: '45% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '14px !important',
            textAlign: 'center !important',
        }
    },
    imageContainer1: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '12px',
            maxWidth: '100% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '4px !important',
            textAlign: 'left !important',
            marginTop: '10px'
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '12px',
            maxWidth: '55% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '14px !important',
            textAlign: 'center !important',

        }

    },
    imageContainer2: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '12px',
            maxWidth: '100% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '4px !important',
            textAlign: 'left !important',
            marginTop: '10px'
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '12px',
            maxWidth: '60% !important',
            aspectRatio: '1.84',
            paddingTop: '2% !important',
            paddingLeft: '14px !important',
            textAlign: 'center !important',
            alignItems: 'center'


        }

    },
    imageContainer3: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '12px',
            maxWidth: '100% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '4px !important',
            textAlign: 'left !important',
            marginTop: '10px'
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '12px',
            maxWidth: '60% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '14px !important',
            textAlign: 'center !important',

        }

    },
    gridRoot: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90% !important'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '50% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
        }
    },
    gridRoot1: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90% !important'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '40% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
        }
    },
    gridRoot2: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90% !important'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '40% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
        }
    },
    gridRoot3: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90% !important'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '50% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
        }
    },
    gridContent: {
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            color: '#6D5DF6',
            flexDirection: 'column !important', // Ensure vertical alignment
            border: '1px solid #FAF9FC', // Border color as background color
            borderRadius: '20px',
            height: '350px !important', // Set height
            textAlign: 'center',
            justifyContent: 'center', // Distribute space around content
            padding: '16px', // Adjusted padding
            backgroundColor: '#FFFFFF', // Set background color
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            maxWidth: '100%',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(0, 128, 229, 0.3), 0 6px 20px rgba(0, 128, 229, 0.3)',

            }
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            color: '#0080e5',
            flexDirection: 'column !important', // Ensure vertical alignment
            border: '1px solid #FAF9FC', // Border color as background color
            borderRadius: '20px',
            height: '280px !important', // Set height
            textAlign: 'center',
            justifyContent: 'flex-start', // Distribute space around content
            padding: '16px', // Adjusted padding
            backgroundColor: '#FFFFFF', // Set background color
            boxShadow: '0 0 18px -4px rgba(177,177,177,.7)',
            width: '100% !important'
            // '&:hover': {
            //     boxShadow: '0 4px 8px rgba(0, 128, 229, 0.3), 0 6px 20px rgba(0, 128, 229, 0.3)',

            // }
        }
    },
    gridItem: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '80% !important',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '25.333% !important',
        }
    },

}));
const HealthCareErp = () => {
    const classes = useStyles();
    const { ref: containerRef, inView: containerInView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: containerRef1, inView: containerInView1 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: containerRef2, inView: containerInView2 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: containerRef3, inView: containerInView3 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: containerRefHero, inView: containerHero } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <ThemeProvider theme={theme}>
            <Container sx={{ margin: '2% auto', maxWidth: '100% !important', padding: '0 !important' }}>
                <Grid size='large' container spacing={4} alignItems="center" sx={{ maxWidth: '90%', margin: '0 auto 40px', display: { xs: 'none', md: 'flex' }, alignItems: 'flex-start', minHeight: '80vh' }}>

                    <Grid item xs={12} md={6} sx={{ maxWidth: '45% !important', paddingTop: '55px !important', paddingLeft: '0 !important', minHeight: '100%' }}>

                        <MainTitle sx={{ textAlign: 'left', wordBreak: 'break-word', fontSize: '6vh' }}>
                            Optimizing Healthcare Operations with  <Typography component="span" sx={{ fontSize: { xs: '24px', md: '6vh' }, color: ' #ff4d4d', fontWeight: 'bold',whiteSpace:'nowrap'}}
                        >
                            FusionERP {' '}  
                        </Typography>
                        System
                        

                        </MainTitle>
                        <SubTitle>
                            Optimizing Healthcare Operations with FusionERP software.            
                        </SubTitle>

                            <Box
                                    textAlign="center"
                                    mb={4}
                                    sx={{
                                        margin: { xs: '10px 0', md: '32px 0' },
                                        width: { xs: '100%', sm: '80%', md: '70%' },
                                        display: 'flex',
                                        flexDirection: { xs: 'column', sm: 'row' },
                                        justifyContent: 'space-around',
                                    }}
                                    >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                        margin: { xs: '10px auto', sm: '0 8px' },
                                        lineHeight: '24px',
                                        fontWeight: 'bold',
                                        backgroundColor: ' #ff4d4d',
                                        border: '1px solid #d6d6d8',
                                        textTransform: 'none',
                                        padding: '1rem 1rem',
                                        width: { xs: '100%', sm: '220px' },
                                        borderRadius: '4rem',
                                        fontSize: '12px',
                                        '&:hover': {
                                            backgroundColor: ' #ff4d4d',
                                            transform:'scale(0.9)'
                                        },
                                        }}
                                    >
                                        Explore Demo Login
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        
                                        href="/request-demo"
                                        sx={{
                                        margin: { xs: '10px auto', sm: '0 8px' },
                                        color:' #ff4d4d',
                                        lineHeight: 1.5,
                                        border: '2px solid  #ff4d4d',
                                        textTransform: 'none',
                                        padding: '1rem 1rem',
                                        width: { xs: '100%', sm: '220px' },
                                        borderRadius: '4rem',
                                        fontSize: '12px',
                                        '&:hover': { 
                                            border: '2px solid  #ff4d4d',
                                            transform:'scale(0.9)'
                                         },
                                        }}
                                    >
                                        Book a Demo
                                    </Button>
                                    </Box>

                    </Grid>
                    <Grid item xs={12} md={6} 
                        sx= {{ 
                            marginTop:'30px',
                            borderRadius: '12px', 
                            maxWidth: '45% !important', 
                            aspectRatio: '1.84', 
                            paddingTop: '0 !important', 
                            paddingLeft: '14px !important', 
                            textAlign: 'right !important', 
                            Height: '700px !important' 
                        }}
                    >
                        <img src= {Healtcareimage}
                            style={{ maxWidth:'100%' }} 
                            alt="image1"
                        />
                    </Grid>
                </Grid>
                <Grid
                container
                spacing={4}
                alignItems="center"
                sx={{
                    minHeight: '60vh',
                    maxWidth: { xs: '100%', sm: '90%', md: '400px' },
                    margin: '0 auto 40px',
                    display: { xs: 'flex', md: 'none' },
                    textAlign: 'center',
                }}
                >
                <Grid 
                    item xs={12} 
                    md={6} sx={{ textAlign: 'center',
                        marginLeft:{
                            xs : '-10px'
                        }
                    }}>
                    <MainTitle
                    sx={{
                        fontSize: { xs: '24px', sm: '28px', md: '32px' },
                        textAlign: 'center',
                        wordBreak: 'break-word',
                    }}
                    >
                    Optimizing Healthcare Operations with{' '}
                    <Typography
                        component="span"
                        sx={{
                        fontSize: { xs: '28px', sm: '32px', md: '6vh' },
                        color: 'red',
                        fontWeight: 'bold',
                        }}
                    >
                        FusionERP{' '}
                    </Typography>
                    system
                    </MainTitle>
                    <SubTitle>
                    Enhance your work automation with the world’s fastest Sales CRM software.
                    </SubTitle>
                    <Box
                    textAlign="center"
                    mb={4}
                    sx={{
                        margin: { xs: '10px 0', md: '32px 0' },
                        width: { xs: '100%', sm: '80%', md: '70%' },
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-around',
                    }}
                    >
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                        margin: { xs: '10px auto', sm: '0 8px' },
                        lineHeight: '24px',
                        fontWeight: 'bold',
                        backgroundColor: '#0080ef',
                        border: '1px solid #d6d6d8',
                        textTransform: 'none',
                        padding: '1rem 1rem',
                        width: { xs: '100%', sm: '220px' },
                        borderRadius: '4rem',
                        fontSize: '12px',
                        '&:hover': {
                            backgroundColor: '#0070d4',
                        },
                        }}
                    >
                        Explore Demo Login
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        href="/request-demo"
                        sx={{
                        margin: { xs: '10px auto', sm: '0 8px' },
                        lineHeight: 1.5,
                        border: '2px solid #0080e5',
                        textTransform: 'none',
                        padding: '1rem 1rem',
                        width: { xs: '100%', sm: '220px' },
                        borderRadius: '4rem',
                        fontSize: '12px',
                        '&:hover': { background: '#edeff5' },
                        }}
                    >
                        Book a Demo
                    </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} 
                    sx={{ maxWidth: '100%', 
                        width: '100%', 
                        paddingLeft: 0, 
                        overflow: 'hidden' }}>
                    <Box
                    sx={{
                        width: '100%',
                        height: { xs: 'auto', sm: '370px' },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                    <img
                        src={Healtcareimage}
                        style={{
                            width: '100%',
                            maxWidth: { xs: '100%', sm: '370px' },
                            height: 'auto',
                        }}
                        alt="CRM GIF"
                    />
                    </Box>
                </Grid>
                </Grid>

                <Grid container spacing={4} alignItems="flex-start" sx={{ 
                        minHeight:{xs:'120vh',md:'110vh'}, maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '100vh', background: '#0080e5', color: '#F1EEFF', padding: '30px 0 !important', }}
                    ref={containerRef}>
                    <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot}>
                        <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
                            Healthcare with FusionERP
                        </MainTypo>
                        <Typography sx={{ fontFamily: '"Poppins", sans-serif',marginTop:{xs:'0px',sm:'0px',md:'30px'} }}>
                            Revolutionizing Healthcare with FusionERP
                            <br />
                            Streamlined Operations, Improved Care and Cost Efficiency"

                        </Typography>
                        <ExploreButton className={containerInView ? 'expand-animation' : ''} sx={{ margin: { xs: '10px auto', md: '0' },color: '#0080e5'}}>
                            Explore Demo Login
                        </ExploreButton>
                    </Grid>
                    
                    <Grid item sx = {{
                        marginLeft:{
                            xs : '-40px',
                        }
                    }}>
                        <img src= {health1}
                            style={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: '550px', 
                            display: 'block',
                            margin: '0 auto',
                            
                            }}
                            alt = "image1"    
                        />
                    </Grid>
                </Grid>

                <Grid container
                    spacing={1}
                    className="container-grid"
                    sx={{
                    marginTop: { xs: '-40px', md: '-120px' },
                    justifyContent: 'center', 
                    textAlign: 'center',
                    alignItems: 'stretch',
                    }}
                >
                    {content.map((content, index) => (
                       <Grid 
                       item 
                       xs={12} 
                       md={4}   
                       key={index} 
                       sx={{
                         display: 'flex',
                         justifyContent: 'center',  
                         maxWidth: { md: '25.333% !important' },
                       }}
                     >
                            <Box sx={{
                                backgroundColor: '#fff',  
                                padding: '16px',         
                                borderRadius: '8px',     
                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                                display: 'flex',
                                flexDirection: 'column', 
                                alignItems: 'center',   
                                width: { xs: '80%', md: '100%' },  
                            }}>
                                <img src={content.image} style={{ textAlign: 'center', margin: '0 auto' }} alt = "imagesorurce"/>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6" sx={{ background: 'none', color: '#0080e5', fontFamily: '"Poppins", sans-serif !important' }}>{content.title}</Typography>
                                    <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins", sans-serif !important' }}>{content.description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid >

                <Grid ref={containerRef1} 
                    container spacing={4} 
                    alignItems="flex-start" 
                    sx={{ 
                        minHeight: {xs:'120vh',md:'100vh'}, maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '100vh', background: '#f9a400', color: '#F1EEFF', padding: '30px 0 !important', }}>
                    <Grid item 
                    
                    sx = {{
                        marginLeft:{
                            xs : '-40px',
                            md : '-20px'
                        }
                    }}>
                        <img 
                        src= {content2image}            
                        style={{
                            width: '550px',
                            height: '393px',
                            maxWidth: '600px', 
                            display: 'block',
                            margin: '0 auto',
                            paddingLeft:'10px'
                        
                          }}
                        alt = "crm4"  
                        />
                    </Grid>
                    <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '5% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot1}>
                        <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
                            Enhanced Healthcare Management Solutions
                        </MainTypo>
                        <Typography 
                            sx={{ fontFamily: '"Poppins", sans-serif', 
                                marginTop:{
                                    xs : '20px',
                                    md:'20px'
                                }

                            }}>
                            Streamline healthcare management with improved data reporting, automated supply chain, and efficient patient visit processes.
                        </Typography>
                        <ExploreButton className={containerInView1 ? 'expand-animation' : ''} sx={{ margin: { xs: '10px auto', md: '0' }, color: '#f9a400' }}>
                            Explore Demo Login
                        </ExploreButton>
                    </Grid>

                </Grid>

                <Grid container
                spacing={2}
                className="container-grid"
                sx={{
                marginTop: { xs: '-40px', md: '-120px' },
                justifyContent: 'center', 
                textAlign: 'center',
                alignItems: 'stretch',
                }}>
                    {content1.map((content, index) => (
                         <Grid 
                         item 
                         xs={12} 
                         md={4}   
                         key={index} 
                         sx={{
                           display: 'flex',
                           justifyContent: 'center',  
                           maxWidth: { md: '25.333% !important' },
                         }}
                       >
                            <Box sx={{
                                backgroundColor: '#fff',  
                                padding: '16px',         
                                borderRadius: '8px',     
                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                                display: 'flex',
                                flexDirection: 'column', 
                                alignItems: 'center',   
                                width: { xs: '80%', md: '100%' }, 
                               
                            }}>
                                <img src={content.image} 
                                    style={{ textAlign: 'center', margin: '0 auto',
                                        maxwidth : {xs : '120px',md:'320px'},
                                     }} 
                                    alt = "content2" 
                                />
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6" sx={{ background: 'none', color: '#f9a400', fontFamily: '"Poppins", sans-serif !important' }}>{content.title}</Typography>
                                    <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins", sans-serif !important' }}>{content.description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid >


                <Grid ref={containerRef2} container 
                spacing={4} alignItems="flex-start" 
                sx={{ 
                    minHeight:{xs : '120vh',md:'110vh'}, 
                    maxWidth: '100% !important', 
                    margin: '60px 0  auto', 
                    display: { xs: 'flex', md: 'flex' }, 
                    justifyContent: { xs: 'center', md: 'space-around' }, 
                    maxHeight: '100vh', 
                    background: '#008c44', 
                    color: '#F1EEFF', 
                    paddingLeft:'30px',
                    paddingTop:'30px !important'
                    }}>
                    <Grid item sx={{ maxHeight: '100%', 
                            paddingTop: { xs: '0 !important', md: '5% !important' }, 
                            textAlign: { xs: 'left', md: 'left' } }} 
                            className={classes.gridRoot2}>
                        <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
                            Enhanced Efficiency and Customization in Healthcare
                        </MainTypo>
                        <Typography 
                        sx={{ fontFamily: '"Poppins", sans-serif', fontSize: '18px', color: '#fff'                             ,marginTop:{
                                xs : '20px',
                                md : '0px'
                            }
                        }}>
                            Boost efficiency with automated inter-departmental processes, seamless real-time communication, and easy customization to fit specific healthcare needs. Frappe Health enhances productivity and adapts to unique requirements.
                        </Typography>
                        <ExploreButton className={containerInView2 ? 'expand-animation' : ''} sx={{ margin: { xs: '10px auto', md: '0' }, color: '#008c44' }} >
                            Explore Demo Login
                        </ExploreButton>
                    </Grid>
                    <Grid 
                    sx = {{
                        marginLeft:{
                            xs : '-30px',
                        }
                    }}>
                        <img src={content3}             
                        style={{
                            width: '550px',
                            height: '393px',
                            maxWidth: '600px',  
                            display: 'block',
                            margin: '0 auto',
                         
                            }}
                        alt = "crm5"    
                        />
                    </Grid>
                </Grid>

                <Grid 
                    container
                    spacing={2}
                    className="container-grid"
                    sx={{
                    marginTop: { xs: '-40px', md: '-120px' },
                    justifyContent: 'center', 
                    textAlign: 'center',
                    alignItems: 'stretch',
                    }}          
                    
                >
                    {content2.map((content, index) => (
                        <Grid 
                        item 
                        xs={12} 
                        md={4}   
                        key={index} 
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',  
                          maxWidth: { md: '25.333% !important' },
                        }}
                      >
                            <Box sx={{
                                    backgroundColor: '#fff',  
                                    padding: '16px',         
                                    borderRadius: '8px',     
                                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                                    display: 'flex',
                                    flexDirection: 'column', 
                                    alignItems: 'center',   
                                    width: { xs: '80%', md: '100%' },  
                                }}                    
                            >
                                <img src={content.image} style={{ textAlign: 'center', margin: '0 auto' }} alt = "content4" />
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6" sx={{ background: 'none', color: '#008c44', fontFamily: '"Poppins", sans-serif !important' }}>{content.title}</Typography>
                                    <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins", sans-serif !important' }}>{content.description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid >
            </Container >

            <Box sx={{ padding: '10px'}}>
                    <Grid item xs={12} md={12}
                        textAlign="center"
                        
                        sx={{
                            display:'row',
                            
                            
                        }}

                    >
                        <Typography sx = {{fontSize:{xs:'1rem',sm:'1rem',md:'2.25rem'},lineHeight:'2.5rem',color: 'rgb(62, 150, 244)',opacity:1,fontWeight:'600'}}>
                            A wide range of healthcare businesses can <br />Leverage FusionERP Healthcare!
                        </Typography>

                        <Typography sx = {{fontSize:'1rem',lineHeight:'2.5rem',marginTop:'2px',fontWeight:'550',color:'grey'}}>
                            No matter what your business requirements are? We have got you covered!
                        </Typography>
                    </Grid>
                <Grid container spacing = {2} justifyContent="center">
                    {healthcareitems.map((item,index) =>(
                        <Grid item 
                        xs={12} 
                        md={4} key={index} 
                        
                        sx={{ ml:-10,mt:2 }}>
                            <Box 
                                
                                sx = {{
                                padding:'10px',
                                width:'73%',
                                height:'80px',
                                alignItems:'center',
                                boxShadow:'2px 2px 2px #00000026',
                                display:'flex',
                                ml:10,
                                border: '1px solid #d6d6d8',
                                borderRadius:'10px',
                                cursor:'pointer'
                                }}
                                
                            >
                                
                                <img src = {item.image}  alt = "image" style={{ width: 46,height:47 }} />
                                <Typography sx = {{justifyContent:'center',ml:2,lineHeight:1.6,fontWeight:"bold",fontSize:'16px',cursor:'pointer'}}>{item.text}</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Container
                sx={{
                    marginTop: '0 !important',
                    minHeight: '50vh', 
                    height: 'auto',
                    paddingTop: { xs: '0px', md: '10px', lg: '10px' },
                    paddingLeft: '0 !important',
                    paddingRight: '0 !important',
                    margin: { xs: '0 auto', md: '0 auto !important' },
                    width:'1240px !important',
                    maxWidth:'100%',
                    display: 'flex',
                    flexDirection: 'column', 
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                ref={containerRefHero}
            >
          <HeroContainer
            sx={{
              backgroundImage: 'url(https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96d4399efb600282b4f_Star.svg),url(https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96d4bcb5f3b507ba65d_Circle.svg)',
              backgroundPosition: '92% 100%,6% 0',
              backgroundRepeat: 'no-repeat !important',
              justifyContent: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              padding: { xs: '0px 10px', md: '0px' },
              minHeight: '60vh',
              height: 'auto',
              width: '100%',
              gridRowGap: { xs: '10px', md: '25px', lg: '30px' },
              maxWidth: { xs: '80%', sm: '50%', md: '100%', lg: '100%' }
            }}
            className={containerHero ? "hero-container" : ""}
          >
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            sx={{
              paddingTop:"30px",
              fontSize: { xs: '24px', md: '40px' },
              fontWeight: {xs : 400,md:600},
              margin: { xs: '10px auto', md: '10px auto' },
            }}
            className={containerHero ? "hero-title" : ""}
          >
            Just FusionERP It !!
          </Typography>
          <Typography
            gutterBottom
            sx={{ fontSize: { xs: '14px', md: '16px' }, color: '#cdd5df' }}
            className={containerHero ? "hero-subtitle" : ""}
          >
            Trust in the Power of FusionERP
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            gap={2}
            pt = {2}
            mt={3}
            sx={{
              margin: { xs: '1px auto', md: '1px auto' },
              flexDirection: { xs: 'column', md: 'row' },
            }}
            className={containerHero ? "hero-buttons" : ""}
          >
            <HeroButton
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: '#fff',
                color: '#007bff',
                width: { xs: '100%', md: 'auto' },
                padding:'16px 36px',
                fontSize:'16px',
                fontWeight:500,
                display : 'flex',
                
              }}
            >
              Start for FREE
            </HeroButton>
            <HeroButton
              variant="button"
              color="inherit"
              sx={{
                border: '2px solid #fff  !important',
                width: { xs: '100%', md: 'auto' },
                padding:'16px 36px',
                fontSize:'16px',
                fontWeight:500,
                display : 'flex'
              }}
              href='/request-demo'
            >
              Book a Demo
            </HeroButton>
          </Box>
          <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={{xs : 2,sm:3,md:6,lg:6}} 
              mt={3}
              sx={{
                flexDirection: {xs: 'column',sm:'column',md:'row',lg:'row'},
                textAlign: 'center', 
                margin: '0 auto',
                width: '100%',
                maxWidth: '95%',
              }}
            >
              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                Free 15 days trial
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                  marginLeft:{
                    xs : '45px',
                    sm : '40px',
                    md : '0px',
                    lg : '0px'
                  
                  }

                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                No credit card required
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                  marginLeft:{
                    xs : '-12px',
                    sm : '-24px',
                    md : '0px',
                    lg : '0px'
                  }
                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                Support 24/7
              </Typography>
            </Box>
          </HeroContainer>
        </Container>


            <Container
                sx={{
                marginTop: {
                    xs: '10%',  
                    sm: '12%', 
                    md: '15%', 
                    lg: '5%'
                },
                maxWidth: '100%' 
                }}
            >
                <Grid
                container
                sx={{
                    marginTop: {
                    xs: '-50px',  
                    sm: '-70px',  
                    md: '-60px',  
                    lg: '-80px' 
                    },
                    justifyContent: 'center' 
                }}
                >
                <Footer />
                </Grid>
            </Container>
        </ThemeProvider >
    );
};
export default HealthCareErp;