import React, { useEffect,useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { InputLabel ,FormGroup,FormControlLabel} from '@mui/material';
import { useLocation } from 'react-router-dom';
import {Checkbox} from "@mui/material";
import siteimage from '../images/site.gif';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        FusionErp
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

const Signuphcm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [domainError, setDomainError] = useState(null);
  const [formError, setFormError] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [isDomainValid, setIsDomainValid] = useState(false);

  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpError, setOtpError] = useState(null);
  const [generatedOtp, setGeneratedOtp] = useState('');
  const[timeLeft,setTimeLeft] = useState(60);


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialDefaultValue = queryParams.get('defaultValue') || '';
  const [defaultValue, setDefaultValue] = useState(initialDefaultValue);
  const [checkedValues, setCheckedValues] = useState([]);
  const [disabled, setDisabled] = useState({ ERP: false, HRMS: false, CRM: false });


  const [prevEmail, setPrevEmail] = React.useState('');
  



  React.useEffect(() => {
   
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('defaultValue');
    navigate(currentUrl.pathname + currentUrl.search, { replace: true });
  }, [navigate]);


  useEffect(() => {    
    if (defaultValue) {
      setCheckedValues([defaultValue]);
    }
  }, [defaultValue]);

  useEffect(() => {
    const isERPChecked = checkedValues.includes('ERPNEXT');
    const isHRMSChecked = checkedValues.includes('HRMS');
    const isCRMChecked = checkedValues.includes('CRM');

    setDisabled({
      ERP: isHRMSChecked || isCRMChecked,
      HRMS: isERPChecked,
      CRM: isERPChecked,
    });
  }, [checkedValues]);


  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setCheckedValues((prev) =>
      prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
    );
  };

  const isAnycheckedvalueornot = checkedValues.length > 0

  const [formValues, setFormValues] = React.useState({
    username: '',
    company: '',
    email: '',
    domain: '',
    password: ''
  });

  const [otpTimer, setOtpTimer] = React.useState(0);
  const [otpMessage, setOtpMessage] = React.useState('');
  const [timerActive, setTimerActive] = React.useState(false);
  const [otpEntered, setOtpEntered] = React.useState(false);

  const handleDomainValidation = async (domain) => {
    if (!domain) return;
    //const response = await fetch(`https://admin.fusionerp.in/api/method/fusion.setup_apps.validate_site_domain?site_name=${domain}`, {

    try {
      const response = await fetch(`https://admin.fusionerp.in/api/method/fusion.setup_apps.validate_site_domain?site_name=${domain}`, {
        method: 'GET',
      });

      const result = await response.json();
      if (!result.message.success) {
        setDomainError('Domain is not available.');
        setIsDomainValid(false);
      } else {
        setDomainError('Domain is available.');
        setIsDomainValid(true);
      }
    } catch (error) {
      setDomainError('An error occurred while validating the domain.');
      setIsDomainValid(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  
    if (name === 'email') {
      if (prevEmail !== value) {
        setPrevEmail(value);
        setTimerActive(false);
        setOtpSent(false);
        setOtp('');
        setOtpMessage('');
        setOtpEntered(false);
        setTimeLeft(60);
        if (otpTimer) {
          clearInterval(otpTimer);
        }
      }
    }
  
    if (name === 'domain') {
      handleDomainValidation(value);
    }
  
    setFormError('');
  };
  

  const handleSendOtp = async () => {
    const otp = Math.floor(100000 + Math.random() * 900000);
    setGeneratedOtp(otp.toString());
    
    setOtpSent(true); 
    setOtpMessage('OTP has been sent, valid for 60 sec');
    setOtpError(null);
    setTimerActive(true);
    setTimeLeft(60);
  
    let timeLeft = 60;
  
    if (otpTimer) {
      clearInterval(otpTimer);
    }
  
    const timer = setInterval(() => {
      timeLeft -= 1;
      setTimeLeft(timeLeft);
  
      if (timeLeft <= 0) {
        clearInterval(timer);
        setTimerActive(false);
        setOtpMessage(<span style={{ color: 'red' }}>OTP expired</span>);
      } else {
        setOtpMessage(
          <>
            OTP has been sent, valid for <span style={{ color: 'red' }}>{timeLeft} sec</span>
          </>
        );
      }
    }, 1000);
  
    setOtpTimer(timer);
  
    try {
      const response = await fetch('https://admin.fusionerp.in/api/method/fusion.setup_apps.send_otp_email', {
        cache: 'no-cache',
        credentials: 'include',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',

          'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate',
          'Pragma':'no-cache',
          'Expires': '0',
        },
        body: JSON.stringify({
          email: formValues.email,
          otp,
        }),
      });
  
      const result = await response.json();
      if (result.data) {
        setOtpError(result.data);
      }
    } catch (error) {
      setOtpError('Failed to send OTP. Please try again.');
    }
  };
  
  
  


  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   const { username, company, email, domain, password } = formValues;
  //   if (!username || !company || !email || !domain || !password) {
  //     setFormError('All fields are required.');
  //     return;
  //   }

  //   if (!isDomainValid) {
  //     setFormError('Domain must be valid before submitting.');
  //     return;
  //   }

  //   if (otp !== generatedOtp) {
  //     setOtpError('Invalid OTP.');
  //     return;
  //   }

  //   setLoading(true);
  //   setProgress(0);

  //   const interval = setInterval(() => {
  //     setProgress((oldProgress) => {
  //       if (oldProgress === 100) {
  //         clearInterval(interval);
  //         return 100;
  //       }
  //       return Math.min(oldProgress + 10, 100);
  //     });
  //   }, 1000);
  //   // const response = await fetch(`https://fusionerp.in/api/method/fusion.setup_apps.validate_site_domain?site_name=${domain}`, {

  //   try {
  //     const response = await fetch('http://hrms_saas:8013/api/method/fusion.setup_apps.create_new_site', {
  //       cache: 'no-cache',
  //       credentials: 'include',
  //       method: 'POST',
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         username,
  //         password,
  //         email,
  //         company_name: company,
  //         site_name: domain,
  //         product: checkedValues
  //       })
  //     });

  //     const result = await response.json();

  //     if (result.message) {
  //       navigate('/fusionerp/success', { state: { domain, sid: result.message[1] }, replace: true });
  //     } else {
  //       setFormError('Signup failed: ' + result.message);
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //     setFormError('An error occurred during signup.');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const { username, company, email,phonenumber, domain, password } = formValues;
    if (!username || !company || !email || !domain || !password) {
      setFormError('All fields are required.');
      return;
    }
  
    if (!isDomainValid) {
      setFormError('Domain must be valid before submitting.');
      return;
    }
  
    if (otp !== generatedOtp) {
      setOtpError('Invalid OTP.');
      return;
    }
  
    setLoading(true);
    setProgress(0);
  
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(interval);
          return 100;
        }
        return Math.min(oldProgress + 10, 100);
      });
    }, 1000);
  
    try {
      const createSiteResponse = fetch('https://admin.fusionerp.in/api/method/fusion.setup_apps.create_new_site', {
        cache: 'no-cache',
        credentials: 'include',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',

          'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate',
          'Pragma':'no-cache',
          'Expires': '0',
        },
        body: JSON.stringify({
          username,
          password,
          email,
          phonenumber,
          company_name: company,
          site_name: domain,
          product: checkedValues,
        }),
      });
  
      // const createSubscriptionResponse = fetch('https://fusionerp.in/api/method/agent.api.create_doc.subsprition_plan', {
      //   cache: 'no-cache',
      //   credentials: 'include',
      //   method: 'POST',
      //   headers: {
      //     'Accept': 'application/json',
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({

      //     full_name: username, 
      //     email_id: email,
      //     annual_revenue: queryParams.get('price'), 
      //     website: queryParams.get('plan'),
      //   }),
      // });
  
      const [siteResult] = await Promise.all([createSiteResponse]);
  
      const siteData = await siteResult.json();
      // const subscriptionData = await subscriptionResult.json();
  
      if (siteData.message ) {
        navigate('/success', { state: { domain, sid: siteData.message[1] }, replace: true });
      } else {
        setFormError('Signup failed: ' + (siteData.message));
      }
    } catch (error) {
      console.error('Error:', error);
      setFormError('An error occurred during signup.');
    } finally {
      setLoading(false);
    }
  };
  

  React.useEffect(() => {
    if (otp.length === 6) {
      if (otp === generatedOtp) {
        setOtpEntered(true);
        setOtpError(null);
      } else {
        setOtpError('Invalid OTP.');
      }
    } else if (otp.length > 6) {
      setOtpError('OTP must be exactly 6 digits.');
    } else {
      setOtpError(null);
    }
  }, [otp, generatedOtp]);

  return (
    <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>     
        {!loading ? (
           <Grid container sx={{ justifyContent: 'center', alignItems: 'center', backgroundColor: '#007ee5', height: '70%', marginTop: '-240px' }}>
          <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Card sx={{ width: '80%', maxWidth: 500, borderRadius: 10, marginTop: '100px' }}>
              <CardContent>
                <ThemeProvider theme={defaultTheme}>
                  <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Avatar sx={{ m: 1, bgcolor: '#007ee5' }}>
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h5">
                        Sign up
                      </Typography>
                      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              id="username"
                              label="User Name"
                              name="username"
                              autoComplete="username"
                              value={formValues.username}
                              onChange={handleChange}
                              error={!formValues.username && !!formError}
                              helperText={!formValues.username && formError}
                              InputProps={{
                                style: { borderRadius:'20px' },
                               }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              id="company_name"
                              label="Company Name"
                              name="company"
                              autoComplete="company_name"
                              value={formValues.company}
                              onChange={handleChange}
                              error={!formValues.company && !!formError}
                              helperText={!formValues.company && formError}
                              InputProps={{
                                style: { borderRadius:'20px' },
                               }}
                            />
                          </Grid>


                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              id="phonenumber"
                              label="Phone Number"
                              name="phonenumber"
                              autoComplete="tel"
                              value={formValues.phone}
                              onChange={handleChange}
                              InputProps={{
                                style: { borderRadius: '20px' },
                                inputMode:'numeric'
                              }}
                              inputProps={{
                                maxLength: 10,
                                pattern : '[0-9]',
                                onInput: (e) => e.target.value = e.target.value.replace(/[^0-9]/g, ''),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              id="email"
                              label="Email"
                              name="email"
                              autoComplete="email"
                              value={formValues.email}
                              onChange={handleChange}
                              error={!formValues.email && !!formError}
                              helperText={!formValues.email && formError}
                              InputProps={{
                                style: { borderRadius: '20px' },
                              }}
                            />
                          </Grid>


                        {!otpEntered && formValues.email && (formValues.email.endsWith('.com') || formValues.email.endsWith(".co")) && (
                          <Grid item xs={12}>
                          <Button
                            fullWidth
                            variant="contained"
                            color='primary'
                            sx={{ width: 'auto', padding: '6px 16px' }}
                            onClick={handleSendOtp}
                            disabled={timerActive}
                          >
                          {timerActive ? `Send OTP (${timeLeft}s)` : 'Send OTP'}
                          </Button>
                          {otpMessage && (
                            <Typography variant="body2" color="text.secondary" align="center" sx={{ ml: 14, mt: -3 }}>
                              {otpMessage}
                            </Typography>
                          )}
                        </Grid>
                        )}


                          {otpSent && (
                            <Grid item xs={12}>
                              <TextField
                                required
                                fullWidth
                                id="otp"
                                label="Enter OTP"
                                name="otp"
                                autoComplete="otp"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                error={!!otpError}
                                helperText={otpError}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {otp.length === 6 ? (                                     
                                        otp === generatedOtp ? <CheckCircleIcon color="success" /> :  <ErrorIcon color="error" />
                                      ) : null},                                 
                                    </InputAdornment>
                                  ),
                                  sx: { borderRadius: '20px' }
                                }}
                              />
                            </Grid>
                          )}
                            {/* <Grid item xs={12}>
                              <Box sx={{ position: 'relative' }}>
                                <TextField
                                  required
                                  fullWidth
                                  name="domain"
                                  label="Domain Name"
                                  type="text"
                                  id="website_domain"
                                  autoComplete="website_domain"
                                  value={formValues.domain}
                                  onChange={handleChange}
                                  error={!!domainError && domainError !== 'Domain is available.'}
                                  helperText={domainError}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Typography
                                          sx={{
                                            color: '#1976d2',
                                            fontSize: { xs: '0.8rem', sm: '1rem' },
                                          }}
                                        >
                                          .fusionerp.in
                                        </Typography>
                                      </InputAdornment>
                                    ),
                                    style: { width: 'calc(100% - 0px)', borderRadius: '20px' },
                                  }}
                                />
                                {domainError && domainError === 'Domain is available.' && (
                                  <Box
                                    sx={{
                                      position: 'absolute',
                                      right: { xs: 50, sm: 100 }, 
                                      top: '38%',
                                      transform: 'translateY(-50%)',
                                    }}
                                  >
                                    <CheckCircleIcon color="success" />
                                  </Box>
                                )}
                                {domainError && domainError !== 'Domain is available.' && (
                                  <Box
                                    sx={{
                                      position: 'absolute',
                                      right: { xs: 50, sm: 100 },
                                      top: '38%',
                                      transform: 'translateY(-50%)',
                                    }}
                                  >
                                    <ErrorIcon color="error" />
                                  </Box>
                                )}
                              </Box>
                            </Grid> */}
                            { otp  && !otpError && otp.length === 6 && (

                              <Grid item xs={12}>
                              <Box sx={{ position: 'relative' }}>
                                <TextField
                                  required
                                  fullWidth
                                  name="domain"
                                  label="Domain Name"
                                  type="text"
                                  id="website_domain"
                                  autoComplete="website_domain"
                                  value={formValues.domain}
                                  onChange={handleChange}
                                  error={!!formValues.domain && domainError && domainError !== 'Domain is available.'}
                                  helperText={formValues.domain ? domainError : ''}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Typography
                                          sx={{
                                            color: '#1976d2',
                                            fontSize: { xs: '0.8rem', sm: '1rem' },
                                          }}
                                        >
                                          .fusionerp.in
                                        </Typography>
                                      </InputAdornment>
                                    ),
                                    style: { width: 'calc(100% - 0px)', borderRadius: '20px' },
                                  }}
                                />
                                {formValues.domain && domainError === 'Domain is available.' && (
                                  <Box
                                    sx={{
                                      position: 'absolute',
                                      right: { xs: 50, sm: 100 },
                                      top: '38%',
                                      transform: 'translateY(-50%)',
                                    }}
                                  >
                                    <CheckCircleIcon color="success" />
                                  </Box>
                                )}
                                {formValues.domain && domainError && domainError !== 'Domain is available.' && (
                                  <Box
                                    sx={{
                                      position: 'absolute',
                                      right: { xs: 50, sm: 100 },
                                      top: '38%',
                                      transform: 'translateY(-50%)',
                                    }}
                                  >
                                    <ErrorIcon color="error" />
                                  </Box>
                                )}
                              </Box>
                              </Grid>
                            )}




                          <Grid item xs={12}>
                            <FormGroup row>
                              <InputLabel sx = {{marginLeft:'15px'}}>Select the Product</InputLabel>
                              <Box sx={{ marginTop: '2px', marginLeft: '15px' }}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={checkedValues.includes('ERPNEXT')}
                                      onChange={handleCheckboxChange}
                                      value="ERPNEXT"
                                      disabled={disabled.ERP}
                                    />
                                  }
                                  label="ERP"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={checkedValues.includes('HRMS')}
                                      onChange={handleCheckboxChange}
                                      value="HRMS"
                                      disabled={disabled.HRMS}
                                    />
                                  }
                                  label="HRMS"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={checkedValues.includes('CRM')}
                                      onChange={handleCheckboxChange}
                                      value="CRM"
                                      disabled={disabled.CRM}
                                    />
                                  }
                                  label="CRM"
                                />
                              </Box>
                            </FormGroup>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              name="password"
                              label="Password"
                              type="password"
                              id="password"
                              autoComplete="new-password"
                              value={formValues.password}
                              onChange={handleChange}
                              error={!formValues.password && !!formError}
                              helperText={!formValues.password && formError}
                              InputProps={{
                                style: { borderRadius:'20px' },
                               }}
                              
                            />
                          </Grid>
                        </Grid>
                        <Box sx={{ position: 'relative', mt: 3 }}>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!formValues.domain || 
                              !isDomainValid ||
                              otp !== generatedOtp || 
                              !otp || 
                              formValues.phonenumber.length !== 10
                              || !isAnycheckedvalueornot
                            }
                          >
                            Sign Up
                          </Button>
                          {loading && (
                            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                              <CircularProgress
                                variant="determinate"
                                value={progress}
                                sx={{ color: '#007ee5' }}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Copyright sx={{ mt: 5 }} />
                  </Container>
                </ThemeProvider>
              </CardContent>
            </Card>
          </Grid>
          </Grid>
        ) : (
          <Grid
            container
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#FFFFFF',
              height: '70%',
              marginTop: '-240px',
            }}
          >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              marginTop: '30px',  
              padding: '0 20px',
              width: '100%',
            }}
          >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '70px',
              flex: '1',
            }}
          >
            <Typography
              color="textSecondary"
              sx={{ marginBottom: '10px', textAlign: 'center', marginLeft: '20px' }}
            >
              Hi <span style={{ fontWeight: 1000 }}>{formValues.username}</span>,
              <br />
              sit back and relax while we are creating your site
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px', 
                marginTop: '140px',
              }}
            >
              {['red', 'green', 'blue', 'orange', 'purple'].map((color, index) => (
                <Box
                  key={index}
                  sx={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: color,
                    borderRadius: '50%',
                    animation: 'scale 1.5s ease-in-out infinite',
                    animationDelay: `${index * 0.3}s`,
                  }}
                />
              ))}
            </Box>
          </Box>

            <Box
              sx={{
                flex: '1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px',
                marginTop:'0 auto'
              }}
            >
              <Grid > 
              <Box
                  component="img"
                  src={siteimage} 
                  alt="loading"
                  sx={{
                    marginLeft: '30px',
                    width: '80%',
                  }}
                />
                {/* <Box
                  component="video"
                  sx={{
                    marginLeft:'30px',
                    width: '80%',
                  }}
                  autoPlay
                  loop
                  muted
                >
                  <source src={siteimage} type="video/mp4" />
                  Your browser does not support the video tag.
                </Box> */}
              </Grid>
            </Box>
          </Box>

          <style>
          {`
            @keyframes scale {
              0%, 100% {
                transform: scale(1);
              }
              50% {
                transform: scale(1.2);
              }
            }
          `}
          </style>
        </Grid>
        )}
      
    </Box>
  );
}

export default Signuphcm;
