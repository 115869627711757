import React,{useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route,useLocation} from 'react-router-dom';
import Signuphcm from './components/Signup';
import Headerhcm from './components/Header';
import LandingPage from './components/Landingpage';
import HRMS from './components/HRMS';
import Crm from './components/Crm';
import Erp from './components/Erp';
import AssetManagement from './components/AssetManagement';
import FusionerpPricecard from "./components/FusionerpPricecard";
import About from './components/FusionerpAbout'
import Fusionerpcontactus from './components/Fusionerpcontactus';
import SignIn from './components/SignIn';
import RequestDemo from './components/RequestDemo';
import SuccessPage from './components/SuccessPage';
import HealthCareErp from './components/HealthCareErp';

function ScrollTop(){
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0,0);
  },[pathname])
  return null;
}

function App() {
  return (
    <Router>
      <ScrollTop />
      <Headerhcm position='sticky' />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/signup" element={<Signuphcm />} />
        <Route path="/hrms" element={<HRMS />} />
        <Route path="/healthcare" element={<HealthCareErp />} />
        <Route path="/crm" element={<Crm />} />
        <Route path="/erp" element={<Erp />} />
        <Route path="/assets" element={<AssetManagement />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/contact" element={<Fusionerpcontactus />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/request-demo" element={<RequestDemo />} />
        <Route path="/pricing" element={<FusionerpPricecard />} />     
        <Route path="/success" element={<SuccessPage />} /> 
      </Routes>
    </Router>
  );
}

export default App;
